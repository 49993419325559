
import * as __star__ from 'react-dom';

const {
default: __default__,
createPortal,
findDOMNode,
hydrate,
render,
unstable_renderSubtreeIntoContainer,
unmountComponentAtNode,
unstable_createPortal,
unstable_batchedUpdates,
flushSync,
__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
} = __star__;

export {
createPortal,
findDOMNode,
hydrate,
render,
unstable_renderSubtreeIntoContainer,
unmountComponentAtNode,
unstable_createPortal,
unstable_batchedUpdates,
flushSync,
__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
};
export default __default__;
